<template>
	<div class="report-list mb-w-max">
		<div class="display-none" style="width: 100%;overflow: auto;overflow-x: scroll;">
			<div class="head">
				<div style="width:50%;text-align: center;" class="number">{{ $t('包裹单号') }}</div>
				<div style="width:50%;text-align: center;" class="time">{{ $t('预报时间') }}</div>
				<div style="width:50%;text-align: center;" class="time">{{ $t('预报时间') }}</div>
			</div>
			<div class="main">
				<div class="item" v-for="(item,index) in list" :key="index">
					<div style="width:50%;text-align: center;" class="_pc-font-18">{{ item.expresssn }}</div>
					<div style="width:50%;text-align: center;" class="_pc-font-18">{{ item.add_time }}</div>
					<div style="width:50%;text-align: center;display: flex;align-items: center;justify-content: center;" class="_pc-font-18">
            <div class="detail" @click="openModals">{{ $t('认领') }}</div>
          </div>
				</div>
				<div style="text-align: center;width: 100%;line-height: 100px;font-size: 18;font-weight: bold;" v-if="list.length == 0">~{{$t('暂无数据')}}~</div>
			</div>
		</div>
		<div class="pc-display-none mb-li-container">
			<div class="mb-li">
				<div class="mb-item" v-for="(item,index) in list" :key="index">
          <div class="mb-item-header">
            <div style="flex: 1;">{{ item.add_time }}</div>
            <div class="detail-new" @click="openModals">{{ $t('认领') }}</div>
          </div>
          <div style="font-weight: bold;margin-top: 5px;">{{ item.expresssn }}</div>
				</div>
			</div>
		</div>
		<div style="margin-top: 20px;margin-left: 20px;margin-bottom: 10px;">
			<Page :total="total" :current.sync="thisFrom.page" show-total @on-change="pageLogChange" :page-size="thisFrom.limit"/>
		</div>
    <!-- 包裹认领 -->
    <div class="mark display-none" v-if="modals">
      <div class="index">
        <div class="main-box">
          <div class="title">
            <div class="_pc-font-32 _b">{{$t('包裹单号')}}</div>
            <div class="iconfont2 icon-cha1 close" @click="close"></div>
          </div>
          <div class="_w-max edit-info">
            <div class="li-box">
              <div class="item">
                <input style="padding: 20px;" type="text" v-model="expresssn" :placeholder="$t('请输入')">
              </div>
            </div>
          </div>
          <div class="btn" @click="submit">{{ $t('提交') }}</div>
        </div>
      </div>
    </div>
    <div v-if="modals" class="_pos-fixed _top _left _h-max _w-max _flex-row-center-mid _mb-pad-x-50 _z-index-3 pc-display-none" style="background-color:rgba(0,0,0,0.2);">
      <div class="_w-max _white-bg _mb-pad-x-40 _mb-pad-y-40" style="border-radius:8px;">
        <div class="_text-center _mb-font-36 _b _mb-mar-bottom-35">{{$t('包裹单号')}}</div>
        <div class="_mb-pad-x-25 _mb-pad-y-25 _mb-mar-bottom-35" style="border-radius:8px;background-color:rgba(247, 247, 247, 1);">
          <input v-model="expresssn" class="_w-max _mb-font-28" type="text" style="background-color:rgba(247, 247, 247, 1);" :placeholder="$t('请输入')">
        </div>
        <div class="_flex-row-mid">
          <div @click="close" class="_flex-1 _flex-row-center-mid _mb-h-75 _mb-font-28 _mb-mar-right-25" style="border:1px solid rgba(66, 132, 220, 1);color:rgba(66, 132, 220, 1);border-radius:6px;">{{$t('取消')}} </div>
          <div @click="submit" class="_flex-1 _flex-row-center-mid _mb-h-75 _mb-font-28 _white" style="border:1px solid rgba(66, 132, 220, 1);background-color:rgba(66, 132, 220, 1);border-radius:6px;">{{$t('确认')}}</div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	import { 
    getNoUserReportList,
    confirmReport,
  } from '@/api/jiyun';
	export default {
		name: 'claimList',
		data() {
			return {
				list: [],
				top: 0,
				total: 0,
				thisFrom: {
					domain_url: window.location.origin,
					page: 1,
					limit: 10,
					status: '',
					keywords: '',
				},
				lang: '',
        expresssn: '', // 快递单号
        modals: false,
			}
		},
		created() {
			this.lang = localStorage.getItem('think-lang') || 'ru';
			this.top = this.$route.query.top-0;
			this.getList()
		},
		methods: {
      // 打开认领
      openModals(){
        this.modals = true;
      },
      // 关闭
      close(){
        this.expresssn = '';
        this.modals = false;
      },
      // 认领
      submit(){
        if(!this.expresssn){
          return this.$Message.error(this.$t('请输入包裹单号'));
        }
        let params = {
          expresssn: this.expresssn,
          domain_url: window.location.origin,
        }
        confirmReport(params).then((res)=>{
          this.$Message.success(res.msg);
          this.expresssn = '';
          this.modals = false;
          this.thisFrom.page = 1;
          this.getList();
        }).catch((err)=>{
          this.$Message.error(err.msg);
        })
      },
      // 列表
			getList(){
				getNoUserReportList(this.thisFrom).then((res)=>{
					this.list = res.data.list;
					this.total = res.data.count;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
      // 分页
			pageLogChange(index){
				this.thisFrom.page = index;
				this.getList();
			},
		}
	}
</script>

<style scoped>
  .detail {
		font-size: 1rem;
		border-radius: 8px;
		border: 1px solid #4284DC;
		color: #4284DC;
		cursor: pointer;
		padding: 0.475rem 0.75rem;
    width: fit-content;
	}
  .detail-new {
		border-radius: 8px;
		border: 1px solid #4284DC;
		color: #4284DC;
		cursor: pointer;
    width: fit-content;
    font-size: 12px;
    padding: 4px 8px;
	}
  .mark {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.4);
		z-index: 999;
	}
  .index {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #333333;
	}
  .main-box {
		padding: 55px;
		border-radius: 16px;
		background-color: #ffffff;
		/* width: 1075px; */
		width: 578px;
	}
	.main-box .title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.main-box .title .close {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background: #e7e7e7;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #999;
		font-size: 16px;
    cursor: pointer;
	}
  .main-box .edit-info .li-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.main-box .edit-info .li-box .item {
		width: 470px;
		margin-bottom: 27px;
	}
	.main-box .btn {
		width: 470px;
		height: 62px;
		border-radius: 12px;
		margin: 0 auto;
		/* margin-top: 40px; */
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		font-size: 20px;
		background-color: #4284DC;
	}
	.main-box .btn:hover {
		cursor: pointer;
		opacity: 0.4;
	}
  input {
		width: 100%;
		border-radius: 7px;
		background-color: #f7f7f7;
		outline: none;
		font-size: 18px;
		/* margin-bottom: 35px; */
	}
	input::placeholder {
		color: #999999;
	}
	.mb-li-container {
		width: 100%;
		padding: 0.9375rem;
	}
	.mb-li-container .mb-li {
		width: 100%;
		/* background: #f5f5f5; */
		padding: 0.9375rem;
		border: 1px solid #f5f5f5;
	}
	.mb-li-container .mb-li .mb-item {
		width: 100%;
		background: #fff;
		padding: 0.9375rem 0.9375rem;
		font-size: 0.875rem;
		border-bottom: 1px solid #f5f5f5;
		/* box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.3); */
		/* margin-bottom: 1.25rem; */
	}
	.mb-li-container .mb-li .mb-item .mb-item-header {
		width: 100%;
		/* padding: 0.9375rem 0rem; */
		font-size: 0.9375rem;
		display: flex;
	}
	.mb-li-container .mb-li .mb-item .mb-item-main {
		width: 100%;
		padding: 0.9375rem 0rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 0.375rem;
		border-bottom: 1px solid #f5f5f5;
	}
	.mb-li-container .mb-li .mb-item .mb-item-footer {
		width: 100%;
		padding: 0.9375rem 0rem;
		color: #999;
		font-size: 0.75rem;
	}
	.report-list .main {
		/* width: 100%; */
		height: 473px;
		overflow: scroll;
		overflow-x: hidden;
		
	}
	.report-list .main .item {
		/* width: 100%; */
		/* height: 70px; */
		padding: 25px 20px;
		border-bottom: 1px solid #E4E4E4;
		color: #333;
		display: flex;
		align-items: center;
	}
	.report-list .main .item:hover {
		background-color: #F5F5F5;
	}
	.report-list .main .item:last-child {
		border-bottom: none;
	}
	
	@media screen and (min-width: 768px){
		.pc-display-none {
			display: none !important;
		}
		.report-list {
			width: 70%;
			/* height: 1000px; */
			background: #FFFFFF;
			border: 1px solid #D9D9D9;
		}
		.report-list .head {
			width: 100%	;
			height: 55px;
			background: #F5F5F5;
			border-bottom: 1px solid #D9D9D9;
			display: flex;
			align-items: center;
			padding: 0 20px;
			color: #999;
			font-size: 18px;
		}
		.my-order-head {
			width: 100%;
			border-bottom: 1px solid #d8d8d8;
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			column-gap: 3.75rem;
		}
		.my-order-head .search {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 9px;
			background: #f5f5f5;
			padding: 0px 16px;
			flex: 1;
		}
		.my-order-head .search .search-left {
			display: flex;
			align-items: center;
			flex: 1;
		}
		.my-order-head .search .search-left .icon-sousuo1 {
			transform: rotateY(180deg);
			color: #383838;
			font-size: 24px;
			margin-right: 12px;
		}
		.my-order-head .search .search-left input {
			flex: 1;
			height: 52px;
			outline: none;
			background-color: #f5f5f5;
		}
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		input {
			outline: none;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0 !important;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem !important;
			padding-right: 0.9375rem !important;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem !important;
			padding-bottom: 0.9375rem !important;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem !important;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.mb-flex-row-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-min-height-205 {
			min-height: 205px;
		}
		.mb-search-container {
			width: 100%;
			background: #f5f5f5;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 0.375rem;
			margin-top: 0.9375rem;
			font-size: 0.9375rem;
			padding: 0.675rem 0;
		}
		.mb-search-container .input-box {
			width: 100%;
		}
		.mb-search-container .input-box label {
			width: 100%;
			display: flex;
			align-items: center;
			flex: 1;
		}
		.mb-search-container .search-btn {
			flex-shrink: 0;
			padding: 0px 0.5rem;
			color: #4284DC;
			border-left: 1px solid;
			border-color: #D9D9D9;
		}
		.mb-search-container .input-box label .icon-sousuo1 {
			transform: rotateY(180deg);
			color: #383838;
			font-size: 1.05rem;
			padding: 0px 0.5rem;
		}
		.mb-search-container .input-box label input {
			background: #f5f5f5;
		}
		.report-list .head {
			margin-top: 0.625rem;
			width: 100%	;
			background: #F5F5F5;
			border-bottom: 1px solid #D9D9D9;
			color: #999;
			display: grid;
			font-size: 1.25rem;
			grid-template-columns: 1fr 1fr 1fr;
		}
		.report-list .head>div {
			padding: 0.625rem 0;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.report-list .head .select-nav::after {
			content: '';
			position: absolute;
			width: 10%;
			height: 0.125rem;
			background-color: #4284DC;
			bottom: 0;
		}
	}
</style>